/* * {
    @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,500;1,500&display=swap');
}

body {
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    font-style: italic;
    background-color: #fffef2;
}

.logo {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
} */

.course-dates {
	margin-top: 20px;
}
.course-dates h2 {
	text-align: center;
	margin: 24px 0px;
}

.course-item {
	border: 1px solid #f3f3f3;
	padding: 20px;
	margin-bottom: 10px;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
}
.course-item h3 {
	color: #f26723;
}

table.basic {
	width: 100%;
}
table.basic,
table.basic th,
table.basic td {
	border: 1px solid black;
}
table.basic th,
table.basic td {
	padding: 10px;
	text-align: center;
}
table.basic th {
	background-color: #f26723;
	color: white;
}
table.basic tr:nth-child(even) {
	background-color: #f2f2f2;
}

a.link-courses:hover {
	color: #d05417;
	border-bottom: 1px solid #f3f3f3;
	padding: 0px 5px;
}

.blog-item {
	background-color: white;
	border: 1px solid #f3f3f3;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
	margin-bottom: 20px;
	padding: 20px;
}
.blog-item h3,
.blog-item p {
	margin-bottom: 10px;
}
.blog-item a {
	color: #f26723;
}
.blog-item .blog-item-date {
	color: #a0a0a0;
}
@media (max-width: 575.98px) {
	.blog-item {
		width: 90%;
	}
	.blog-item .blog-item-right .wrapper {
		padding: 22px 0px;
	}
}

.blog-detail ul {
	margin-left: 20px;
}
.blog-detail ul li {
	list-style-type: square;
}
.blog-detail .blog-image {
	margin-bottom: 10px;
}
.blog-date {
	padding-top: 10px;
	color: #a0a0a0;
}

.blog-text h3 {
	font-family: "Oswald", sans-serif;
	font-size: 18px;
	margin-top: 10px;
}

.lds-ripple {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-ripple div {
	position: absolute;
	border: 4px solid #f26723;
	opacity: 1;
	border-radius: 50%;
	animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
	animation-delay: -0.5s;
}
@keyframes lds-ripple {
	0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 0;
	}
	4.9% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 0;
	}
	5% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
	}
}

.page-login-form-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}
.page-login-form-wrapper h1 {
	font-size: 24px;
	padding-top: 10px;
}
.page-login-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 300px;
	gap: 20px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding-bottom: 10px;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
}
.page-login-form input {
	padding: 4px 10px;
	width: 90%;
	border: 1px solid #ccc;
}
.page-login-form button {
	padding: 4px 10px;
	background-color: #f26723;
	color: white;
	border: none;
	cursor: pointer;
	width: 90%;
}

.course-item-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.input-checkbox {
	display: flex;
	justify-content: start;
	gap: 10px;
}

.input-checkbox label {
	padding-top: 10px;
	cursor: pointer;
}

.minHeightPage {
	min-height: 500px;
	h1.very-big {
		color: #f26723;
		font-size: 115px;
		padding-bottom: 15px;
	}
	.page-title {
		padding-bottom: 20px;
	}
	.btn-back {
		display: block;
		float: left;
		background-color: #f26723;
		border-radius: 20px;
		color: white;
		padding: 10px 20px;
		margin: 20px 0px;
		&:hover {
			background-color: #ffffff;
			color: #f26723;
		}
	}
}

/** Dashboard */
.dash-link {
	margin-bottom: 10px;
}
.dash-menu {
	display: flex;
	padding: 20px 0px;
	gap: 20px;
}

/** Table **/
.table-wrapper {
	display: block;
	margin: 20px 0px;
}
.table {
	font-family: Arial, Helvetica, sans-serif;
	border-collapse: collapse;
	width: 100%;

	td,
	th {
		border: 1px solid #ddd;
		padding: 8px;
	}

	tr {
		cursor: pointer;
	}

	tr:nth-child(even) {
		background-color: #f2f2f2;
	}
	tr:hover {
		background-color: #ddd;
	}

	th {
		padding-top: 12px;
		padding-bottom: 12px;
		text-align: left;
		background-color: #f26723;
		color: white;
	}
}

/** Manage page **/
.actions {
	display: flex;
	justify-content: start;
	gap: 10px;
	margin-top: 10px;

	.action-item {
		button {
			background-color: #f26723;
			color: white;
			padding: 5px 20px;
			border: none;
			border-radius: 5px;
			cursor: pointer;
		}
	}
}

/** Modal **/
.modal-close {
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
}

.thumbnail {
	width: 100%;
	height: 200px;
	object-fit: cover;
}

.button {
	display: block;
	text-align: center;
	background-color: #f26723;
	color: white;
	padding: 5px 10px;
	margin: 20px 0px;
	width: 170px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.space-below {
	margin-bottom: 20px;
}
